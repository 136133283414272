// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:3839a835-3cfe-496e-ae94-b2e32108ce8e",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_rEKSlDjag",
    "aws_user_pools_web_client_id": "jf2pak94jv2j1ijunrqud7fjt",
    "oauth": {}
};


export default awsmobile;
